<template>
  <div class="find-pwd-container bg-gray-02 auth-bg">
    <div>
      <img
        src="/assets/images/brand/logo/BIGCSTUDIO_Logotype_Black_2x_Comp.png"
        class="studio-logo"
        width="91"
        alt="BIGC-STUDIO black Logo"
      />
    </div>
    <div>
      <img
        src="/assets/images/brand/logo/BIGCSTUDIO_Logotype_Black_2x_Comp.png"
        class="studio-logo"
        width="91"
        alt="BIGC-STUDIO black Logo"
      />

      <div class="studio-find-pwd-form">
        <h2>비밀번호를 잊으셨나요?</h2>
        <p class="sub-text-s1 text-gray-second">
          빅크 가입 시 사용한 이메일 주소를 입력해주시면 비밀번호를 재설정 할 수
          있는 링크를 보내드립니다.
        </p>
        <input-underline
          name="email"
          type="email"
          label="이메일 주소"
          placeholder="example@bigc.im"
          :default-value="state.email"
          :error-message="state.errorMessage"
          @updateData="(value) => actions.updateEmail(value)"
          @keydown.enter="actions.sendResetEmail()"
        ></input-underline>
        <button
          class="sub-title-s1"
          :class="{ active: state.isEmail }"
          @click="actions.sendResetEmail()"
        >
          비밀번호 재설정 메일 보내기
        </button>
      </div>
    </div>
    <teleport to="#teleport">
      <warning-modal
        v-if="state.showSnsUserInfoModal"
        :warning-title="state.snsUserInfoModalTitle"
        warning-text="소셜 로그인을 이용해주세요."
        confirm-text="로그인하기"
        @hideModal="actions.closeShowSnsUserInfoModal()"
        @confirm="actions.moveToSnsLogin()"
      ></warning-modal>

      <warning-modal
        v-if="state.showEmailUserInfoModal"
        warning-title="입력하신 이메일로 비밀번호 재설정 링크를 보냈습니다."
        :show-cancel-button="false"
        :warning-text="`${state.email}으로 보낸 이메일을 확인해주세요. 메일을 못 받으셨다면 스팸 메일함도 확인해주세요.`"
        confirm-text="확인"
        @hideModal="actions.closeShowEmailUserInfoModal()"
        @confirm="actions.closeShowEmailUserInfoModal()"
      ></warning-modal>
    </teleport>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { useRouter } from "vue-router";
import ApiService from "@/api";
import InputUnderline from "../../../components/console/inputs/InputUnderline.vue";
import WarningModal from "../../../components/console/modals/WarningModal";
import helper from "@/helper";

export default {
  name: "FindPassword",
  components: {
    InputUnderline,
    WarningModal,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      email: "",
      errorMessage: "",
      showSnsUserInfoModal: false,
      snsUserInfoModalTitle: "",
      showEmailUserInfoModal: false,
      snsUerInfoModalTitle: "",
      isEmail: computed(() => {
        return helper.validateEmail(state.email.trim());
      }),
    });

    const actions = {
      updateEmail: (value) => {
        state.email = value;
      },
      sendResetEmail: async () => {
        if (!helper.validateEmail(state.email)) {
          state.errorMessage = "이메일 형식이 맞는지 확인해 주세요.";
          return;
        }

        const payload = new FormData();
        payload.append("email", state.email);

        await ApiService.postPasswordRequest(payload)
          .then((res) => {
            if (res.data.success) {
              if (res.data.meta) {
                state.showSnsUserInfoModal = true;
                state.snsUserInfoModalTitle = res.data.meta;
              } else {
                state.showEmailUserInfoModal = true;
              }
            } else {
              state.errorMessage = res.data.message;
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              state.errorMessage = error.response.data.message;
            } else {
              console.log(error);
            }
            state.requestLoading = false;
          });
      },
      closeShowEmailUserInfoModal: () => {
        state.showEmailUserInfoModal = false;
      },
      closeShowSnsUserInfoModal: () => {
        state.showSnsUserInfoModal = false;
      },
      moveToSnsLogin: () => {
        router.push({
          name: "auth.login",
          params: { isSnsUser: true },
        });
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
